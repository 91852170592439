.dashboard {
    &_container {
        min-height: 100%;
        min-width: 100%;
        background-color: var(--var-main-background-color);
        @media (min-width: 992px) {
            padding: 40px 52px 20px 63px;
        }

        @media (max-width: 992px) {
            display: flex;
            align-items: center;
            margin-top: 4em;
        }
    }

    &_info {
        color: white;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.71px;
        justify-content: center;
        align-items: center;
        padding-left: 21px;
        padding-right: 21px;
        height: 100%;
        padding-bottom: 15px;

        &_wrapper {
            height: 100%;
            padding-top: 38px;
        }

        &_text {
            max-width: 300px;
        }

        &_link {
            width: 100%;
            font-size: 12px;
            font-weight: normal;;
            letter-spacing: normal;
            text-align: right;
            color: #ffffff;
            padding-right: 10px;

            &:hover{
                cursor: pointer;
            }
        }
    }

    &_earnings {
        &_container {
            height: 100%;
            padding-bottom: 15px;
        }

        &_text {
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
        }

        &_link {
            font-size: 12px;
            font-weight: normal;;
            letter-spacing: normal;
            text-align: right;
            color: #8da3ff;
            padding-right: 10px;

            &:hover{
                cursor: pointer;
            }
        }

        &_wrapper {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 100%;
        }

        
        &_header {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1.13px;
            text-align: center;
            color: #353535;
        }

        &_money {
            font-size: 72px;
            font-weight: bold;
            letter-spacing: 1.5px;
            text-align: center;
            color: #1a237e;
        }

        &_subtitle {
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 1px;
            text-align: center;
            
            color: #808080;
        }
    }

    &_refference {
        margin: 0px;
        padding: 30px 0 15px 10px;

        &_wrapper {
            min-height: 100%;
            margin-bottom: 50px;
        }

        &_text {
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
        }

        &_header {
            font-weight: bold;
            letter-spacing: 1.13px;
            color: #353535;
        }

        &_link {
            font-size: 12px;
            font-weight: normal;;
            letter-spacing: normal;
            text-align: right;
            color: #8da3ff;
            padding-right: 10px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    &_new_referer {
        margin: 0px;
        color: white;
        padding:  0 15px 0 15px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media (min-width: 992px) {
            flex-direction: row;
            padding: 0 0 0 40px;

            height: 330px;
            justify-content: initial;
            align-items: initial;
            text-align: initial;
        }

        &_text {
            font-stretch: normal;
            font-style: normal;
            line-height: normal;

            &_wrapper {
                max-width: 360px;
                height: 100%;
                justify-content: center;
                align-items: start;
                flex-direction: column;
            }
        }

        &_title {
            font-size: 24px;
            margin-bottom: 10px;
            font-weight: bold;
            letter-spacing: 1.71px;

            &_wrapper {
                padding-bottom: 10px;
            }
        }

        &_body {
            font-size: 18px;
            font-weight: normal;
            letter-spacing: 1.29px;
        }

        &_button {
            max-width: 300px;
            height: 65px;
            padding: 23px 10px 21px 10px;
            font-weight: bold;
            margin: 18px 0 0 0;
            border-radius: 5px;
            background-color: #00bfa5;

            &:hover {
                cursor: pointer;
            }

            &_container {
                @media (max-width: 992px) {
                    width: 100%;
                }
                
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 10px;  
            }
        }   
    }
}

.image {
    &_group {

        &_wrapper {
            @media (max-width: 992px) {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }

        max-height: 314px;
        max-width: 314px;
        width: 100%;

        @media (min-width: 992px) {
            position: absolute;
            align-self: flex-end;
        }
      
    }
}

