

.sidebar {
    
    &_container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
    }

    &_header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        min-height: 80px;
        border-bottom: 2px solid  #e5e5e5;
        &_close{
            display:flex;
            flex-direction: column;
            span{
                width: 31px;
                height: 1px;
                margin: 7px 0 0;
                background: var(--var-hamburger-menu);
                transform: rotate(45deg);
                &:nth-child(even){
                    transform: rotate(135deg);
                    margin-top: -2px;
                }
            }
        }
        &_logo_mobile{
            padding-left: 20px;
        }
    }

    &_route {

        &_logo {
            height: 31px;
            width: 31px;
            filter: invert(99%) sepia(3%) saturate(0%) hue-rotate(149deg) brightness(107%) contrast(101%);
            &_active {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(107deg) brightness(91%) contrast(230%);
            }
        }

        &_container {
            cursor: pointer;
            min-height: 58px;
            min-width: 80%;
            margin: 10px;
            padding: 11.5px 0px 12.5px 12px;
            color:  #808080;
            border-radius: 10px;
            display: flex;
            justify-content: left;
            align-items: center;
            &_active {
                background-color: #1a237e;
                color: white;
                animation: pop .3s linear ;
            }
        }

        

        &_text {
            font-size: 16px;
            padding-bottom: 3px;

            &_container {
                justify-content: center;
                align-items: center;
            }
        }

        &_notification {
            padding: 2px 8px;
            border-radius: 50%;
            background-color: red;
            color: white;
            font-size: 14px;
        }
    }
    &_logout {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-self: flex-end;
        align-items: center;
        padding: 15px;
        width: fit-content;
        cursor: pointer;
        h3{
            font-size: 16px;
            padding-bottom: 3px;
            padding: 0 12px;
        }
        img{
            width: 19px;
            height: 19px;}
    }
}

.layout {
    &_container {
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    &_sidebar {
        padding: 0px;
        z-index: 5;
        border-right: 2px solid  #e5e5e5;
        transition: left .4s ease-in-out .3s;

        @media (max-width: 576px) {
            position: fixed;
            top: 0;
            bottom: 0;
            left: -80%;
            width: 80%;
            background: white;        
            &_show{
            left: 0
            }
        }
    }
    &_top_header{
        display: flex;
        justify-content: space-around;
        align-items: center;
        min-height: 80px;
        border-bottom: 2px solid  #e5e5e5;
        &_hamburger_container{
           height: 40px;
           width: 40px;
           padding: 8px 10px; 
           cursor: pointer;
        }
        &_hamburger_menu{
            display: flex;
            flex-direction: column;
            span{
                width: 31px;
                height: 1px;
                margin: 7px 0 0;
                background: var(--var-hamburger-menu);
                z-index: 10;
            }
            &_toCross{ 
                position: fixed;
                z-index: 10;
                span[class="bar1"] {
                animation: bar1ToCross 1.2s linear both alternate;
                }
                span[class="bar2"] {
                animation: bar2ToCross 1.2s linear both alternate;
                }
                span[class="bar3"] {
                    animation: bar3ToCross 1.2s linear both alternate;
                }
            }
            &_toMenu{
                span[class="bar1"] {
                    animation: bar1ToMenu 1.2s linear both alternate;
                }
                span[class="bar2"] {
                animation: bar2ToMenu 1.2s linear both alternate;
                }
                span[class="bar3"] {
                    animation: bar3ToMenu 1.2s linear both alternate;
                }
            }
        }
        &_logo{
            padding-top: 8px;
        }
    }
}

@keyframes pop{
    50%  {transform: scale(1.01);}
  }

  @keyframes bar1ToCross{
    20% {opacity: 0;}
    55% {opacity: 0;}
    80% { opacity: 1;transform: none;}
    100%  {transform: rotate(-45deg) translate(-7px, 4px);}
  }

  @keyframes bar2ToCross{
    20% {opacity: 0;}
    55% {opacity: 0;}
    80% {opacity: 1;}
    95%  {opacity: 0;}
    100%  {opacity: 0;}
  }

  @keyframes bar3ToCross{
    20% {opacity: 0;}
    55% {opacity: 0;}
    80% { opacity: 1;transform: none;}
    100%  {transform: rotate(45deg) translate(-7px, -4px);}
  }

  @keyframes bar1ToMenu{
    0% {transform: rotate(-45deg) translate(-7px, 4px);}
    15% {opacity: 0;}
    55% {opacity: 0;}
    80% { opacity: 1; transform: rotate(-45deg) translate(-7px, 4px);}
    100% {transform: none;}
  }

  @keyframes bar2ToMenu {
      0% {opacity: 0;}
      80% { opacity: 0;}
      100% { opacity: 1; }
  }

  @keyframes bar3ToMenu{
    0% {transform: rotate(45deg) translate(-7px, -4px);}
    15% {opacity: 0;}
    55% {opacity: 0;}
    80% { opacity: 1;transform: rotate(45deg) translate(-7px, -4px);}
    100% {transform: none;}
  }