.gegevens {
    &_container {
        min-height: 100%;
        min-width: 100%;
        background-color: var(--var-main-background-color);
        @media (min-width: 992px) {
            padding: 40px 52px 20px 63px;
        }

        @media (max-width: 992px) {
            display: flex;
            align-items: center;
            margin-top: 4em;
        }
    }

    &_wrapper {
        @media (max-width: 991px) {
            margin-top: 7em;
        }

        @media (max-width: 768px) {
            margin-top: 2em;
        }
    }

    &_doelen {
        margin: 0px;
        padding: 30px 0 15px 10px;

        &_wrapper {
            min-height: 100%;
            margin-bottom: 50px;
        }

        &_text {
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
        }

        &_header {
            font-weight: bold;
            letter-spacing: 1.13px;
            color: #353535;
        }

        &_link {
            font-size: 12px;
            font-weight: normal;;
            letter-spacing: normal;
            text-align: right;
            color: #8da3ff;
            padding-right: 10px;
        }

        &_button {
            margin-right: auto;
        }
        
        &_row_button{
            align-self: center;
            height: 19px;
            cursor: pointer;
            &_fix{
                padding:0 20px;
            }
        }
    }
}

.doelen_modal {
    color: #353535;
    min-height: 440px;
    align-items: center;
  
    &_success {
        background-color: #ffa78c;
        color: white;
  
      &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
      &_title {
        font-size: 24px;
        letter-spacing: 1.71px;
        font-weight: bold;
        text-align: center;
      }
  
      &_subtitle {
        padding: 10px;
        font-size: 18px;
        letter-spacing: 1.29px;
        text-align: center;
        }
    }
  
  
    &_header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px 15px;
        border-bottom: 1px solid var(--var-light-grey);
        height: 20%;
        width: 100%;
        min-height: 85px;
    
        &_title {
            font-size: 18px;
            letter-spacing: 1.13px;
            padding-bottom: 5px;
            font-weight: bold;
        }
    
        &_subtitle {
            font-size: 14px;
            text-align: center;
        }
        &_close{
            background-color: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            padding: 4px;
            height: 26px !important;
            position: absolute;
            cursor: pointer;
            top: 10px;
            right: 10px;
        }
    }
  
    &_body {
        max-height: 60vh;
        overflow: auto;
        display: flex;
        padding-top: 20px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 40%;
        width: 100%;
        min-height: 230px;
        font-size: 14px;

        &_checkbox_container {
            display: flex;
            justify-content: space-between;
            width: 80%;
        }

        &_input {
            width: 80%;
            text-align: start;
            display: flex;
            flex-direction: column;
            margin: 15px 0px;

            span {
            margin: 6px 0;
            font-weight: bold;
            }

            input, select {
            width: 100%;
            height: 54px;
            padding: 16px 43px 14px 21px;
            border: 1px solid var(--var-light-grey);
            }
        }

        &_toggle {
            border: 1px solid gray;
            border-radius: 20px;
            width: 50px;
            height: 50px;
        }
    }

    &_footer {
        @media (max-width: 1300px) {
            flex-wrap: wrap;
        }
        @media (max-width: 992px) {
            width: 100%;
            div[id="invite_button"]{
                position: unset !important;
                border: none;
                background: none;
            }
        }
        &_disabled{
            div[id="invite_button"]{
                div{
                    background: var(--var-light-grey);
                }
            }
        }
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;  
    } 
}

.amb_invite_modal {
    color: #353535;
    min-height: 440px;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 1em;

    &_copied {

        &_wrapper {
            padding-top: 10px;
            height: 40px;
            text-align: center;
        }
        
        animation-name: example;
        animation-duration: 3s;
        visibility: hidden;
        ::-moz-selection {
            background-color: none;
        }
        ::selection {
            background-color: none;
        }
        
        
        @keyframes example {
        0%	{visibility: visible;} 
        0%   {opacity: 0.25;}
        25%   {opacity: 1;}
        100% {opacity: 0;}
        }
    }
  
    &_header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px 15px;
        border-bottom: 1px solid var(--var-light-grey);
        height: 20%;
        width: 100%;
        min-height: 85px;
    
        &_title {
            font-size: 18px;
            letter-spacing: 1.13px;
            padding-bottom: 5px;
            font-weight: bold;
        }
    }
  
    &_body {
        display: flex;
        padding-top: 20px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 40%;
        width: 100%;
        min-height: 230px;
        font-size: 14px;
        &_spacer{
            justify-content: center;
            width: 80%;
            display: flex;
            border-bottom: 1px solid var(--var-light-grey);
            margin: 20px 0;
            span{
                padding: 10px;
                background: white;
                margin-bottom: -20px;
                font-weight: bold;
                font-size: 18px;
            }
        }

        &_input {
            width: 80%;
            text-align: start;
            display: flex;
            flex-direction: column;

            span {
            margin: 6px 0;
            font-weight: bold;
            }

            input {
            width: 100%;
            height: 54px;
            padding: 16px 43px 14px 21px;
            border: 1px solid var(--var-light-grey);
            }
        }
    }
}