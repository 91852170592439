.logo_item {  
    &_logo {
        display: flex;
        text-transform: uppercase;
        color: white;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        max-height: 35px;
        max-width: 35px;
        height: 35px;
        width: 35px;
        border-radius: 100%;
        background-color: #1a237e;    
    }

}
