.referenties {

    &_container {
        min-height: 100%;
        min-width: 100%;
        background-color: var(--var-main-background-color);
        @media (min-width: 992px) {
            padding: 40px 52px 20px 63px;
        }

        @media (max-width: 992px) {
            display: flex;
            align-items: center;
            margin-top: 4em;
        }
    }

    &_invite_button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        width:100%;
        max-width: 300px;
        height: 65px;
        padding: 23px 10px 21px 10px;
        font-weight: bold;
        margin: 18px 0 16px 0;
        border-radius: 5px;
        background-color: var(--var-button-light-green);
        align-self: center;
        &:hover {
            cursor: pointer;
        }

        &_wrapper {


        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 16px;
        @media (max-width: 992px) {
            background-color: #ffffff;
            position: fixed;
            bottom: 0;
            width: 100%;
            border-top: solid 1px #e4e4e4;;
        }
        }
    }

    &_new_referer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: var(--var-white-text);
        margin-left: 0;
        padding: 5px 0;
        height: 515px;

        &_text {
            font-stretch: normal;
            font-style: normal;
            line-height: normal;

            &_wrapper {
                max-width: 360px;
                height: 100%;
                justify-content: center;
                align-items: start;
                flex-direction: column;
            }
        }

        &_title {
            font-size: 24px;
            margin-bottom: 10px;
            font-weight: bold;
            letter-spacing: 1.71px;
        }

        &_button {
            display: flex;
            align-items: center;
            color: #ffffff;
            &_wrapper {
                @media (max-width: 992px) {
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    border-top: solid 1px #e4e4e4;;
                }
            }

            max-width: 300px;
            height: 65px;
            padding: 23px 10px 21px 10px;
            font-weight: bold;
            margin: 18px 0 16px 0;
            border-radius: 5px;
            background-color: var(--var-button-light-green);
            align-self: center;
            &:hover {
                cursor: pointer;
            }
        }   
    }
}

.image {
    &_group {
        position: absolute;
        height: 260px;
        align-self: flex-end;
        @media (max-width:1085px) {
            height: auto;
            margin-bottom: -18px;
        }
    }
}

// generic style deviations:
.logo_height_fix{
    div[class="col-2 "]{
        padding-left: 0;
        margin-right: 5px;
        @media only screen and (min-width: 992px){
            margin-right: 15px; 
        }
    }
    div[class="col-2"]{
        display: none;
    }
    div{
        padding: 0;
        div{
            padding: 0;
        }
    }
}

.ambassadeur_info_modal {
    color: #353535;
    padding-bottom: 25px;
    align-items: center;
  
    &_success {
        background-color: #ffa78c;
        color: white;
  
      &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
      &_title {
        font-size: 24px;
        letter-spacing: 1.71px;
        font-weight: bold;
        text-align: center;
      }
  
      &_subtitle {
        padding: 10px;
        font-size: 18px;
        letter-spacing: 1.29px;
        text-align: center;
        }
        img{
            height: unset;
        }
    }
  
  
    &_header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px 15px;
        border-bottom: 1px solid var(--var-light-grey);
        height: 20%;
        width: 100%;
        min-height: 85px;
    
        &_title {
            font-size: 18px;
            letter-spacing: 1.13px;
            padding-bottom: 5px;
            font-weight: bold;
        }
    
        &_subtitle {
            font-size: 14px;
            text-align: center;
        }
        &_close{
            background-color: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            padding: 4px;
            height: 26px !important;
            position: absolute;
            top: 10px;
            right: 10px;
            &:hover {
                cursor: pointer;
            }
        }
    }
  
    &_body {
        display: flex;
        padding-top: 20px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 40%;
        width: 100%;
        min-height: 230px;
        font-size: 14px;
        &_data {
            width: 80%;
            text-align: start;
            display: flex;
            flex-direction: column;
            margin: 10px;
            h4, span {
                margin: 6px 0;
            }
        }
    }

    &_remove {
        &_wrapper {
            span{
                border-bottom: 1px solid var(--var-light-grey);
                &:hover {
                    cursor: pointer;
                } 
            }

        }
    }

    &_footer {
        @media (max-width: 992px) {
            width: 100%;
            div[id="invite_button"]{
                position: unset !important;
                border: none;
                background: none;
            }
        }
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;  
        width: 80%;
    } 
}

.amb_invite_modal {
    color: #353535;
    min-height: 440px;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 1em;

    &_copied {

        &_wrapper {
            padding-top: 10px;
            height: 40px;
            text-align: center;
        }
        
        animation-name: example;
        animation-duration: 3s;
        visibility: hidden;
        ::-moz-selection {
            background-color: none;
        }
        ::selection {
            background-color: none;
        }
        
        
        @keyframes example {
        0%	{visibility: visible;} 
        0%   {opacity: 0.25;}
        25%   {opacity: 1;}
        100% {opacity: 0;}
        }
    }
  
    &_success {
        background-color: #ffa78c;
        color: white;
  
      &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
      &_title {
        font-size: 24px;
        letter-spacing: 1.71px;
        font-weight: bold;
        text-align: center;
      }
  
      &_subtitle {
        padding: 10px;
        font-size: 18px;
        letter-spacing: 1.29px;
        text-align: center;
        }
        img{
            height: unset;
        }
    }
  
  
    &_header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px 15px;
        border-bottom: 1px solid var(--var-light-grey);
        height: 20%;
        width: 100%;
        min-height: 85px;
    
        &_title {
            font-size: 18px;
            letter-spacing: 1.13px;
            padding-bottom: 5px;
            font-weight: bold;
        }
    
        &_subtitle {
            font-size: 14px;
            text-align: center;
        }
        &_close{
            background-color: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            padding: 4px;
            height: 26px !important;
            position: absolute;

            &:hover {
                cursor: pointer;
            }
            top: 10px;
            right: 10px;
        }
    }
  
    &_body {
        display: flex;
        padding-top: 20px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 40%;
        width: 100%;
        min-height: 230px;
        font-size: 14px;
        &_spacer{
            justify-content: center;
            width: 80%;
            display: flex;
            border-bottom: 1px solid var(--var-light-grey);
            margin: 20px 0;
            span{
                padding: 10px;
                background: white;
                margin-bottom: -20px;
                font-weight: bold;
                font-size: 18px;
            }
        }

        &_input {
            width: 80%;
            text-align: start;
            display: flex;
            flex-direction: column;

            span {
            margin: 6px 0;
            font-weight: bold;
            }

            input {
            width: 100%;
            height: 54px;
            padding: 16px 43px 14px 21px;
            border: 1px solid var(--var-light-grey);
            }
        }
    }

    &_footer {
        @media (max-width: 992px) {
            width: 100%;
            div[id="invite_button"]{
                position: unset !important;
                border: none;
                background: none;
            }
        }
        &_disabled{
            div[id="invite_button"]{
                div{
                    background: var(--var-light-grey);
                }
            }
        }
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;  
        width: 80%;
    } 
}

.beforeText {
    margin-right: .5em;
}