.referentie_modal {
  color: #353535;
  min-height: 440px;
  align-items: center;

  &_success {
    background-color: #ffa78c;
    color: white;

    &_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &_title {
      font-size: 24px;
      letter-spacing: 1.71px;
      font-weight: bold;
      text-align: center;
    }

    &_subtitle {
      padding: 10px;
      font-size: 18px;
      letter-spacing: 1.29px;
      text-align: center;
    }
  }

  &_select {
    &_wrapper {
      text-align: start;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;

      select {
        border: 1px solid var(--var-light-grey);
        font-size: 15px;
        font-weight: bold;
        height: 40px;
        text-transform: capitalize;

        option {
          font-size: 15px;
          height: 40px;
          min-height: 40px;
          padding-top: 10px;
          padding-bottom: 10px;
          text-transform: capitalize;
        }
      }
    }
  }

  &_form {
    padding-left: 20px;
    padding-right: 20px;

    &_permission {
      display: flex;
      flex-direction: row;
      padding: 2px;
      padding-top: 20px;
    }

    &_text {
      padding: 2px;
      font-size: 12px;
      color: #808080;
      line-height: 1.25;
      text-align: start;

      &_underline {
        text-decoration: underline;
      }
    }

    &_radio {
      width: 15px !important;
      height: 15px;
    }

    &_input {
      &_row {
        span {
          font-size: 14px;
          font-weight: bold;
        }

        text-align: start;
        display: flex;
        flex-direction: column;

        input {
          width: 100%;
        }

        &_last {
          border-bottom: 1px solid var(--var-light-grey);
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      

      &_radio {
        color: #808080;
        line-height: 1.25;
        font-size: 12px;
        padding-top: 2px;

        &_wrapper {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }    
  }

  &_footer {
      @media (max-width: 992px) {
          width: 100%;
      }
      
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;  

      &_button {
        min-width: 250px;
        text-align: center;
        max-width: 300px;
        height: 65px;
        padding: 23px 10px 21px 10px;
        font-weight: bold;
        margin: 18px 0 0 0;
        border-radius: 5px;
        background-color: #00bfa5;
    
        &:hover {
            cursor: pointer;
        }
    }
}   

  &_header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid var(--var-light-grey);
    height: 20%;
    width: 100%;
    min-height: 85px;

    &_close {
      height: 29px;
      width: 29px;
      background-color: rgba(128,128,128, 0.4);
      font-size: 24px;
      font-weight: bold;
      color: white;
      position: absolute;
      margin-right: 10px;
      right: 15px;
      margin-bottom: 35px;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover{
        cursor: pointer;
      }
    }

    &_title {
      font-size: 18px;
      letter-spacing: 1.13px;
      padding-bottom: 5px;
      font-weight: bold;
    }

    &_subtitle {
      font-size: 14px;
    }
  }

  &_body {
    display: flex;
    padding-top: 20px;
    align-items: center;
    flex-direction: column;
    height: 40%;
    width: 100%;
    min-height: 230px;
  }
}