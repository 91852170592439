.link_field {
    color: #353535;
    min-height: 440px;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 1em;

    &_copied {

        &_wrapper {
            padding-top: 10px;
            height: 40px;
            text-align: center;
        }
        
        animation-name: example;
        animation-duration: 3s;
        visibility: hidden;
        ::-moz-selection {
            background-color: none;
        }
        ::selection {
            background-color: none;
        }
        
        
        @keyframes example {
        0%	{visibility: visible;} 
        0%   {opacity: 0.25;}
        25%   {opacity: 1;}
        100% {opacity: 0;}
        }
    }
  
    &_header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px 15px;
        border-bottom: 1px solid var(--var-light-grey);
        height: 20%;
        width: 100%;
        min-height: 85px;
    
        &_title {
            font-size: 18px;
            letter-spacing: 1.13px;
            padding-bottom: 5px;
            font-weight: bold;
        }
    }
  
    &_body {
        display: flex;
        padding-top: 20px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 40%;
        width: 100%;
        min-height: 230px;
        font-size: 14px;
        &_spacer{
            justify-content: center;
            width: 80%;
            display: flex;
            border-bottom: 1px solid var(--var-light-grey);
            margin: 20px 0;
            span{
                padding: 10px;
                background: white;
                margin-bottom: -20px;
                font-weight: bold;
                font-size: 18px;
            }
        }

        &_input {
            width: 80%;
            text-align: start;
            display: flex;
            flex-direction: column;

            span {
            margin: 6px 0;
            font-weight: bold;
            }

            input {
            width: 100%;
            height: 54px;
            padding: 16px 43px 14px 21px;
            border: 1px solid var(--var-light-grey);
            }
        }
    }
}