.header {
    font-family: 'Noto Sans HK', sans-serif;

    &_name {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #353535;
    }

    &_subtitle {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        color: #1a237e;
    }
}