.referenties {

    &_container {
        min-height: 100%;
        min-width: 100%;
        background-color: var(--var-main-background-color);
        @media (min-width: 992px) {
            padding: 40px 52px 20px 63px;
        }

        @media (max-width: 992px) {
            display: flex;
            align-items: center;
            margin-top: 4em;
        }
    }
}

// generic style deviations:
.logo_height_fix{
    div[class="col-2 "]{
        padding-left: 0;
        margin-right: 5px;
        @media only screen and (min-width: 992px){
            margin-right: 15px; 
        }
    }
    div[class="col-2"]{
        display: none;
    }
    div{
        padding: 0;
        div{
            padding: 0;
        }
    }
}
    
.referentie_info_modal {
    color: #353535;
    min-height: 440px;
    align-items: center;
    
    &_success {
        background-color: #ffa78c;
        color: white;
    
        &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        }
    
        &_title {
        font-size: 24px;
        letter-spacing: 1.71px;
        font-weight: bold;
        text-align: center;
        }
    
        &_subtitle {
        padding: 10px;
        font-size: 18px;
        letter-spacing: 1.29px;
        text-align: center;
        }
        img{
            height: unset !important;
        }
    }
    
    
    &_header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px 15px;
        border-bottom: 1px solid var(--var-light-grey);
        height: 20%;
        width: 100%;
        min-height: 85px;
    
        &_title {
            font-size: 18px;
            letter-spacing: 1.13px;
            padding-bottom: 5px;
            font-weight: bold;
        }
    
        &_subtitle {
            font-size: 14px;
            text-align: center;
        }
        &_close{
            background-color: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            padding: 4px;
            height: 26px !important;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    
    &_body {
        display: flex;
        padding: 20px 0px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 40%;
        width: 100%;
        min-height: 230px;
        font-size: 14px;
        &_data {
            width: 80%;
            text-align: start;
            display: flex;
            flex-direction: column;
            margin: 10px;
            h4, span {
                margin: 6px 0;
            }
        }
    }

    &_footer {
        @media (max-width: 992px) {
            width: 100%;
            div[id="invite_button"]{
                position: unset !important;
                border: none;
                background: none;
            }
        }
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;  
        width: 80%;
    } 
}