@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@100;300;400;500;700;900&display=swap');

body{
  margin: 0;
  min-height: 100vh;
  font-family: 'Work Sans', sans-serif;
}

.app{
  display: flex;
  min-height: 100vh;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}