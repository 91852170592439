.button {
  width: 100%;

  &_modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; 
    z-index: 15;
    top: 0;
    left: 0;

    &_closed {
      display: none;
    }
  }

  @media (max-width: 992px) {
    justify-content: center;
  }
}