.button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    width:100%;
    max-width: 300px;
    height: 65px;
    padding: 23px 10px 21px 10px;
    font-weight: bold;
    margin: 18px 0 16px 0;
    border-radius: 5px;
    background-color: var(--var-button-light-green);
    align-self: center;
    text-align: center;
    
    &:hover {
        cursor: pointer;
    }
    &_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px 0 16px;
        @media (max-width: 992px) {
            bottom: 0;
            width: 100%;
        }
    }
}