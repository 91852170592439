/*style-colors*/
:root {
    --var-main-background-color: rgba(141, 164, 255, 0.05);
    --var-button-light-green: #00bfa5;
    --var-light-grey: #e4e4e4;
    --var-hamburger-menu: #353535;
}


/*text-colors*/
:root{
    --var-white-text: #fff;
    --var-black-text: #000;
    --var-not-active-text: #e4e4e4;
}
