.sparen {

  &_container {
    min-height: 100%;
    min-width: 100%;
    background-color: var(--var-main-background-color);
    @media (min-width: 992px) {
        padding: 40px 52px 20px 63px;
    }

    @media (max-width: 992px) {
        display: flex;
        align-items: center;
        margin-top: 4em;
    }
  }

  &_redeem {
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;

    &_image {
      height: 117px;

      &_container {
        margin-top: 20px;
      }
    }

    &_title {
      font-size: 18px;
      letter-spacing: 1.13px;
      min-height: 17%;
      font-weight: bold;
    }

    &_button {
      color: white;
      max-width: 300px;
      width: 210px;
      min-width: 185px;
      height: 65px;
      padding: 23px 10px 21px 10px;
      font-weight: bold;
      margin: 18px 0 0 0;
      border-radius: 5px;
      background-color: var(--var-button-light-green);
      align-self: center;
      &:hover {
          cursor: pointer;
      }

      &_container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px; 
      }
    }   
  }

  &_overview {
    padding: 0;
    margin: 0;

    &_header {
      padding: 30px 30px 30px 30px;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.13px;
      color: #353535;
      height: 23px;
      border-bottom: solid 1px #e4e4e4;;
    }   

    &_label {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 32px;
      width: 100%;
      letter-spacing: 1.13px;
      padding-top: 10px;
      color: #353535;
      font-size: 18px;

      &_text {
        padding-left: 0px;
        margin-left: 0px;
      }

      &_spacer {
        position: absolute;
        width: 80%;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #e4e4e4;
      }

      &_minus {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 10px;
        height: 1px;
        background-color: #1a237e;
        
      }

      &_summary {
        margin-bottom:30px;
      }

      &_last {
        margin-bottom: 10px;
      }

      &_currency {
        letter-spacing: 1px;
        font-size: 24px;
        color: #1a237e;
        font-weight: bold;
        text-align: right;
      
      }      
    }
  }
}