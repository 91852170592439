.account {

    &_container {
        min-height: 100%;
        min-width: 100%;
        background-color: var(--var-main-background-color);
        @media (min-width: 992px) {
            padding: 40px 52px 20px 63px;
        }

        @media (max-width: 992px) {
            display: flex;
            align-items: center;
            margin-top: 4em;
        }
    }

    &_hr {
        border: solid 1px #e0e0e0;
        width: 100%;
    }

    &_title {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1.13px;
        color: #353535;
        margin-top: 1.3em;
    }

    &_title_align_left {
        width: 100%;
        padding-left: 25px;
    }

    &_cancel {
        font-size: 12px;
        line-height: 2;
        text-align: center;
        color: #9a9a9a;
        text-decoration: none;
    }

    &_card {
        display: flex;
        justify-content: center;
        align-items: center;

        &_form {
            margin-top: 1em;
            padding: 20px;
            width: 100%;

            label {
                font-size: 14px;
                font-weight: bold;
                color: #353535;
            }

            &_button {
                opacity: 0.6;
                border-radius: 5px;
                background-color: #808080;
                outline: none;
                width: 100%;
                padding: 1.4em 2em;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 1.14px;
                color: #ffffff;
                border: none;
                margin-top: 2em;
                margin-bottom: 20px;
                min-height: 55px;
                &:hover {
                    cursor: initial;
                }

                @media(max-width: 1374px) {
                    padding: 0.6em 2em;
                }

                &_active {
                    background-color: #1a237e !important;
                    opacity: 1 !important; 
                    &:hover {
                        cursor: pointer;
                    }
                }
              }
            }
        }
}