.datagrid {
    &_wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 28px 16px;

    }

    &_button {
        max-width: 50px;
        float: right;
    }

    &_header{
        display: flex;
        justify-self: space-between;
        align-items: flex-end;
        flex-direction: row;
        height: 66px;
        width: 100%;
        padding-bottom: 14px;
        &_item{
            color: var(--var-not-active-text);
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 1px;
            &_active{
                font-size: 18px;
                font-weight: bold;
                color: var(--var-black-text);
            }
        }
    }
    &_row{
        width: 100%;
        align-items: center;
        display: flex;
        border-top: 1px solid var(--var-light-grey);
        height: 82px;
        margin: 5px 0;
        &_item{
            display:block;
            max-width: 98%;
            overflow: hidden !important;
            text-overflow: ellipsis;
            span{
                font-size: 16px;
            }
        }
        img{
            align-self: center;
            height: 19px;
            cursor: pointer;
        }
    }
}