.footer {
    position: fixed;
    bottom: .5%;
    right: 4%;

    font-size: 12px;
    line-height: 2;
    color: #9a9a9a;

    @media(max-width: 992px) {
position: initial;
text-align: center;
    }

    a {
        text-decoration: none;
        color: #1a237e;
    }
}