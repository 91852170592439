.default {
    outline: none;
    width: 73%;
    margin: .5em 0;
    padding: 16px 47px 14px 23px;
    border-radius: 5px;
    border: solid 1px #e0e0e0;
    background-color: #ffffff;

    font-size: 14px;
    line-height: 1.71;
    color: #353535;
    font-family: 'Work Sans', sans-serif;
    padding-right: 10px;

    &::placeholder {
    color: #9a9a9a;
    }

    @media(max-width: 758px) {
        width: 100%;
    }
}

.card {
    @extend .default;
    width: 100%;
}

.small {
    @extend .default;
        width: 47%;
        padding-right: .5em;
}

.smallFirst {
    @extend .default;
        width: 50%;
        padding-right: 0;
}