.modal_button_width {
    width: 100%;
}

.referenties {
    &_new_referer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: var(--var-white-text);
        margin-left: 0;
        padding: 5px 0;
        height: 515px;

        &_text {
            font-stretch: normal;
            font-style: normal;
            line-height: normal;

            &_wrapper {
                max-width: 360px;
                height: 100%;
                justify-content: center;
                align-items: start;
                flex-direction: column;
            }
        }

        &_title {
            font-size: 24px;
            margin-bottom: 10px;
            font-weight: bold;
            letter-spacing: 1.71px;
        }

        &_button {
            display: flex;
            align-items: center;
            color: #ffffff;
            &_wrapper {
                @media (max-width: 992px) {
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    border-top: solid 1px #e4e4e4;;
                }
            }

            max-width: 300px;
            height: 65px;
            padding: 23px 10px 21px 10px;
            font-weight: bold;
            margin: 18px 0 16px 0;
            border-radius: 5px;
            background-color: var(--var-button-light-green);
            align-self: center;
            &:hover {
                cursor: pointer;
            }
        }   
    }
}

.image {
    &_group {
        position: absolute;
        height: 260px;
        align-self: flex-end;
        @media (max-width:1085px) {
            height: auto;
            margin-bottom: -18px;
        }
    }
}

// generic style deviations:
.logo_height_fix{
    div[class="col-2 "]{
        padding-left: 0;
        margin-right: 5px;
        @media only screen and (min-width: 992px){
            margin-right: 15px; 
        }
    }
    div[class="col-2"]{
        display: none;
    }
    div{
        padding: 0;
        div{
            padding: 0;
        }
    }
}