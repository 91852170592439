.card {
    &_wrapper {
        padding: 20px 20px 28px 16px;

        &_disabled {
            padding: 20px 20px 28px 16px;
            pointer-events: none;
            opacity: .6;
        }
    }

    &_modal {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_container {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }
}