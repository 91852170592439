.register {
    display: flex;
    flex-direction: row;
    max-height: 100vh;
    height: 100vh;

    @media(max-width: 758px) {
        flex-direction: column-reverse;
    }

    &_error {
        font-size: 14px;
        color: red;
    }

    #checkbox {
        border: solid 1px #b80000;
    }

    p {
        font-size: 12px;
        color: #9a9a9a;
        line-height: 2;
    }

    a {
        text-decoration: none;
        color: #1a237e;
    }

    button {
        width: 73%;
        margin: 12px 4px 20px 0;
        padding: 19px 0 20px;
        border-radius: 5px;
        background-color: #00bfa5;
        border: none;
        outline: none;

        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1.43px;
        color: #ffffff;

        @media(max-width: 758px) {
            width: 100%;
            margin: 0 auto;
            margin-top: 10px;
        }
    }

    &_left {
        width: 43vw;

        @media(max-width: 1440px) {
            width: 60vw;
        }
        
        @media(max-width: 758px) {
            width: 90%;
            margin: 0 auto;
            text-align: center;
            height: 75%;
        }

        &_checkmarks {
            padding-top: 5px;

            &_checkmark {
                width: 2%;
            }

            div {
                padding-bottom: 5px;
                align-items: center;

                span {
                    font-size: 14px;
                    padding-left: 5px;
                }
            }

            @media(max-width: 758px) {
                display: none;
            }
        }

        &_content {
            padding-left: 14%;

            &_img {
                padding-top: 11vh;
                padding-bottom: 5vh;

                &_logo {
                    width: 36%;
                    padding-bottom: 1em;
                }

                @media(max-width: 758px) {
                    padding-top: 0;
                }
            }


            @media(max-width: 758px) {
                padding: 0;
                width: 100%;
                padding-top: 3em;
            }
        
        &_container {

            &_message {
                margin-top: 12em;

                @media(max-width: 758px) {
                    margin-bottom: 21em;
                    margin-top: 9em;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &_info {
                    font-size: 15px !important;
                    color: #1a237e !important;
                    width: 78%;
                    margin-bottom: 2em;
                    letter-spacing: 1.29px;
                }
            }

            h1 {
                font-size: 32px;
                font-weight: bold;
                letter-spacing: 2.29px;
                color: #1a237e;
                width: 78%;

                @media(max-width: 758px) {
                    font-size: 24px;
                    letter-spacing: 1.71px;
                    margin-top: -0.2em;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &_form {
                height: 52vh;

                @media(max-height: 600px) {
                    height: 60vh;
                }

                @media(min-height: 758px) {
                    height: 65vh;
                }

                .right {
                    margin-right: -4em;
                }

                &_row {
                    display: flex;
                    justify-content: space-between;
                    width: 73%;

                    @media(max-width: 758px) {
                        width: 100%;
                    }
                }
                
                    &_input {
                        outline: none;
                        width: 73%;
                        margin: .5em 0;
                        padding: 16px 47px 14px 23px;
                        border-radius: 5px;
                        border: solid 1px #e0e0e0;
                        background-color: #ffffff;
    
                        font-size: 14px;
                        line-height: 1.71;
                        color: #9a9a9a;
                        font-family: 'Work Sans', sans-serif;

                        @media(max-width: 758px) {
                            width: 100%;
                        }
                    }

                &_agree {
                    width: 73%;

                    @media(max-width: 758px) {
                        width: 100%;
                    }
    
                    &_checkbox {
                        margin: 12px 5px 41px 0;
                        border-radius: 3px;
                        border: solid 1px #b80000;
                        background-color: #ffffff;
                        outline: none;
                    }
    
                    p {
                        font-size: 12px;
                        line-height: 1.25;
                    }
                }

                &_login {
                    margin-top: 1em;
                }
            }


            &_steps {
                margin-bottom: -0.8em;

                @media(max-width: 758px) {
                    margin-bottom: 1em;
                }
            }

            &_terms {
                height: 3vh;

                @media(max-height: 600px) {
                    padding-bottom: 10vh;
                }
            }


        }
    }
    }

    &_right {
        width: 57vw;
        height: 100%;
        background-color: #3d5afe;

        @media(max-width: 758px) {
            max-height: 25vh;
        }

        img {
            display: none;
        }

        @media(max-width: 1440px) {
            width: 40vw;
        }

        &_checkmarks {
            display: none;
        }

        @media(max-width: 758px) {
            height: 100%;
            width: 100vw;

            &_checkmarks  {
                display: block;
            }

            img {
                display: block;
                position: absolute;
                left: 50%;
                top: 5.3%;
                transform: translate(-50%, -50%);
            }

            div {
                display: block;
                font-family: 'Work Sans', sans-serif;
                color: white;
                position: absolute;
                left: 50%;
                @media(max-width: 425px) {
                    top: 13.3%;    
                }

                top: 11.3%;
                transform: translate(-50%, -50%);

                div {
                    position: initial;
                    left: initial;
                    transform: initial;
                    padding-bottom: 5px;
                    align-items: center;

                    span {
                        font-size: 14px;
                        padding-left: 5px;
                    }
                }
                img {
                    display: block;
                    position: initial;
                    left: initial;
                    top: initial;
                    transform: initial;
                }
            }
        }
    }

    &_groepskorting {
        position: absolute;
        height: 87%;
        width: 50%;

        top: 3%;
        left: 32.2%;

        @media(max-width: 1440px) {
            top: 3%;
            left: 48.8%;
            width: 48%;
        }

        @media(max-width: 758px) {
            top: -18.9%;
            left: 36%;
            width: 27%;
        }

        @media(max-width: 670px) {
            top: -18.9%;
            left: 32.9%;
            width: 32%;
        }

        @media(max-width: 425px) {
            top: -18.9%;
            left: 27%;
            width: 44.5%;
        }
    }
}