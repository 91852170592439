.landing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    height: 100vh;
    width: 100vw;
    background-color: #3d5afe;
    .title {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
    }
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 400px;
        max-width: 70vw;
        height: 75px;
        border: 2px solid #fff;

        &_reward {
            color: #fff;
            font-size: 32px;
            font-weight: bold;
        }
    }
    .links {
        display: flex;
        align-items: center;
        color: #fff;
        &_link {
            padding: 10px;
            a {
                text-decoration: none;
                color: #fff;
            }
        }
    }
    .error {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
    }
    .lds_ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds_ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
      }
      .lds_ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds_ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds_ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      
}