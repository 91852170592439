.logo_item {

    &_wrapper {
        margin: 0px;
        padding: 10px 0 0 0px;
        width: 100%;
        @media (max-width: 992px) {
            display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        }
        &_text{
            display: flex;
            justify-content: center;
        }
    }

    &_text {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
    }

    &_header {
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 1.13px;
        color: #353535;
    }

    &_subtitle {
        font-size: 12px;
        color: #808080;
    }

    &_triangle {
        position: absolute;
        right: 0;
        top: 15px;
        width: 40px;
        height: 40px;
        background: #00bfa5;
        clip-path: polygon(50% 50%, 100% 100%, 100% 0);
    }

    &_hr {
        width: 95%;
        margin: 0 auto;
        border: 1px solid var(--var-light-grey);
        margin-top: 1em;
    }
}
